import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import ResultsMeta from '@rentivo/gatsby-core/src/components/search/ResultsMeta/component';
import {
  makeSelectSearchData
} from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import { withPageContext } from '@rentivo/gatsby-core/src/context/PageContext';

const mapStateToProps = createSelector(
  makeSelectSearchData(),
  (data) => ({
    data,
  })
);

const enhance = compose(
  withPageContext(),
  connect(mapStateToProps)
);

export default enhance(ResultsMeta);
