import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import loadable from '@loadable/component';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { PropertyComponent } from '@rentivo/gatsby-core/src/context/PropertyContext';
import { window } from 'browser-monads';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFuzzyLatLng, getBboxFromHits } from '@rentivo/gatsby-core/src/utils/geo';
import SearchMarker from '@rentivo/gatsby-core/src/components/map/SearchMarker';
import SearchMarkerPopup from '@rentivo/gatsby-core/src/components/map/SearchMarkerPopup';
import messages from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/ResultsMap/messages';
import {
  StyledMapResults,
  StyledFullscreenControl,
  StyledNav,
  StyledOnMapMove,
  defaultMapStyles
} from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/ResultsMap/styles';
import GoogleApiWrapper from '@rentivo/gatsby-core/src/components/generic/GoogleApiWrapper';
import ExperienceMarkers from '@rentivo/gatsby-core/src/components/map/ExperienceMarkers';
import { Checkbox, Text } from '@chakra-ui/react';

const MapGL = loadable(() => import('react-map-gl/dist/es6'));
const Marker = loadable(() => import('@rentivo/gatsby-core/src/containers/SearchProvider/components/results/ResultsMap/imports/Marker'));
const Popup = loadable(() => import('@rentivo/gatsby-core/src/containers/SearchProvider/components/results/ResultsMap/imports/Popup'));
const NavigationControl = loadable(() => import('@rentivo/gatsby-core/src/containers/SearchProvider/components/results/ResultsMap/imports/NavigationControl'));
const FullscreenControl = loadable(() => import('@rentivo/gatsby-core/src/containers/SearchProvider/components/results/ResultsMap/imports/FullscreenControl'));


const PopupInfo = React.memo(({popupInfo, setPopupInfo}) => {
  if(!popupInfo) return null;
  const lat = Number(popupInfo.location.lat);
  const lon = Number(popupInfo.location.lon);
  if(!lat || !lon) return null;

  return (
    <Popup
      key={`map-popup-${Math.random()}`}
      tipSize={5}
      offsetTop={-3}
      anchor="bottom"
      longitude={lon}
      latitude={lat}
      closeOnClick={false}
      className="map-popup property-popup"
      onClose={() => setPopupInfo(null)}
    >
      <PropertyComponent component={SearchMarkerPopup} property={popupInfo}/>
    </Popup>
  );
});

const boundOptions = {
  padding: 30,
  duration: 0,
  maxZoom: 12
};

const ResultsMapComponent = (
  {
    data,
    mapKey,
    mapStyle,
    defaultZoom,
    maxZoom,
    minZoom,
    useFuzzyRadius,
    fuzzyRadius,
    defaultCenter,
    searchOnMapMove,
    searchOnMapMoveConfig,
    setSearchOnMapMove,
    usePlaceNames,
    includeExperiences,
    showExperiencesByDefault,
    className,
    filters: {
               location: {
                           setFilter: locationSetFilter,
                           filter: locationFilter,
                           filterOptions: locationFilterOptions,
                         },
               page: {
                           setFilter: pageSetFilter,
                         }
             }
  }) => {


  // ------- SETUP INIT VARIABLES & STATE ------------
  // -------------------------------------------------

  const { hits } = data;
  const mounted = useRef(false);
  const mapRef = useRef(null);
  const mapInitRef = useRef(false);
  const { formatMessage } = useIntl();

  const defaultLocationValue = locationFilterOptions?.defaultValue && null;
  const locationValue = locationFilter?.value && defaultLocationValue;
  const locationBounds = (locationValue) ? locationValue.slice(1) : null;

  const [isMapMoved, setIsMapMoved] = useState(false);
  const [prevHits, setPrevHits] = useState(null);

  const [viewport, setViewport] = useState({
    latitude: defaultCenter.lat,
    longitude: defaultCenter.lng,
    zoom: defaultZoom,
    pitch: 0
  });
  const [interaction, setInteraction] = useState({
    isDragging: false,
    isZooming: false
  });
  const [popupInfo, setPopupInfo] = useState(null);

  // ------- DEBUGGING PURPOSES ------------
  // -------------------------------------------------
  //const counter = useRef(1);
  //counter.current = counter.current + 1;
  //console.log({counter: counter.current, isMapMoved, prevHits, hits, interaction, viewport, popupInfo});


  // ------- DEFINE FUNCTIONS ------------------------
  // -------------------------------------------------

  const onChangeSearchOnMove = e => setSearchOnMapMove(e.target.checked);

  const updateViewportToMapGL = useCallback(() => {
    const mapGL = mapRef.current.getMap();
    setViewport({
      ...viewport,
      latitude: mapGL.getCenter().lat,
      longitude: mapGL.getCenter().lng,
      zoom: mapGL.getZoom()
    });
  }, [mapRef, viewport]);

  const updateMapBoundsBasedOnLocation = useCallback(() => {
    if(locationBounds && locationBounds.length === 4) {
      const mapGL = mapRef.current.getMap();
      mapGL.fitBounds([[locationBounds[0], locationBounds[1]], [locationBounds[2], locationBounds[3]]], boundOptions);
      updateViewportToMapGL();
    }
  }, [locationBounds, mapRef, viewport]);

  const debouncedUpdateMapBoundsBasedOnMarkers = useCallback(debounce(() => {
    if(isMapMoved === false) { // Only update view when location filter has been changed
      if(!hits || hits.length === 0) {
        updateMapBoundsBasedOnLocation();
        return false;
      }

      const mapGL = (mapRef.current) ? mapRef.current.getMap() : null;
      if(mapGL) {
        const bbox = getBboxFromHits(hits);
        if(bbox) {
          mapGL.fitBounds(bbox, boundOptions);
          updateViewportToMapGL({mapRef, viewport, setViewport});
        } else {
          updateMapBoundsBasedOnLocation();
        }
      }
    } else {
      setIsMapMoved(false);
    }
  }, 100), [hits, isMapMoved, locationBounds, mapRef, viewport]);

  const searchResults = useCallback(() => {
    if(!locationFilterOptions || !searchOnMapMove) return false; // || !loadGoogle
    if(interaction.isDragging || interaction.isZooming) {

      const geocoder = new window.google.maps.Geocoder;
      const mapGL = mapRef.current.getMap();
      const mapGlBounds = mapGL.getBounds();
      const center = [mapGL.getCenter().lng, mapGL.getCenter().lat];
      const bounds = [[mapGlBounds._sw.lng, mapGlBounds._sw.lat], [mapGlBounds._ne.lng, mapGlBounds._ne.lat]];
      let placeName = formatMessage(messages.unknownPlaceName);

      if(usePlaceNames) {
        locationSetFilter([formatMessage(messages.loadingPlaceName), bounds]);

        geocoder.geocode({'location': {lat: center[1], lng: center[0]}}, (geoResults, status) => {
          if (status === 'OK') {
            const geo = (geoResults[0]) ? geoResults[0] : null;
            if (geo) {
              //const postalTown = geo.address_components.filter(g => g.types.includes('postal_town'));
              const importantAddressComponents = geo.address_components.filter(g => g.types.includes('postal_town') || g.types.includes('locality') || g.types.includes('administrative_area_level_2') || g.types.includes('administrative_area_level_1'));
              placeName = (importantAddressComponents.length) ? `${importantAddressComponents[0].short_name}` : geo.formatted_address;
            }
          }
          pageSetFilter(1);
          locationSetFilter([placeName, bounds]);
        });
      } else {
        pageSetFilter(1);
        locationSetFilter([formatMessage(messages.mapViewPlaceName), bounds]);
      }

    }
  }, [mapRef, locationFilterOptions, searchOnMapMove, interaction, usePlaceNames]);

  const onMapMove = useCallback(() => {
    if(!isMapMoved) setIsMapMoved(true);
  }, [isMapMoved]);


  // ------- DEFINE COMPONENTS -----------------------
  // -------------------------------------------------

  const renderMarker = useCallback((property) => {
    const { location, id } = property || {};
    let { lat, lon } = location || {};
    lat = Number(lat);
    lon = Number(lon);
    if(!lat || !lon) return null;

    if(useFuzzyRadius) {
      const fuzzyLatLng = getFuzzyLatLng(lat, lon, fuzzyRadius);
      lat = fuzzyLatLng[0];
      lon = fuzzyLatLng[1];
    }

    return (
      <Marker key={`marker-${id}`} longitude={lon} latitude={lat} offsetLeft={-12} offsetTop={-31}>
        <PropertyComponent component={SearchMarker} onMarkerClick={setPopupInfo} property={property}/>
      </Marker>
    );
  }, [useFuzzyRadius, fuzzyRadius]);

  // ------- LIFECYCLE TRIGGERS ----------------------
  // -------------------------------------------------

  useEffect(() => {
    if(popupInfo) {
      setSearchOnMapMove(false);
    } else {
      setSearchOnMapMove(searchOnMapMoveConfig);
    }
  }, [popupInfo]);

  useEffect(() => {
    if(mapRef.current && !mapInitRef.current) {
      debouncedUpdateMapBoundsBasedOnMarkers();
      mapInitRef.current = true;
    }
  }, [mapRef.current]);

  useEffect(() => {
    if (mounted.current === false) {
      mounted.current = true;
    } else {
      if(!isEqual(prevHits, hits)) { // && googleLoaded
        debouncedUpdateMapBoundsBasedOnMarkers();
        setPrevHits(hits);
      }
    }

  }, [hits]);

  // ------- RENDER ----------------------------------
  // -------------------------------------------------

  return (
    <StyledMapResults className={className}>
      <GoogleApiWrapper>
        <MapGL
          ref={el => mapRef.current = el}
          {...viewport}
          mapStyle={mapStyle}
          width="100%"
          height="100%"
          onViewportChange={setViewport}
          mapboxApiAccessToken={mapKey}
          onInteractionStateChange={setInteraction}
          onTouchMove={onMapMove}
          onMouseDown={onMapMove}
          onWheel={searchResults}
          onTouchEnd={searchResults}
          onMouseUp={searchResults}
          maxZoom={maxZoom}
          minZoom={minZoom}
          style={defaultMapStyles}
        >
          {hits.map(hit => renderMarker(hit))}
          {popupInfo && <PopupInfo popupInfo={popupInfo} setPopupInfo={setPopupInfo}/>}

          {includeExperiences && (
            <ExperienceMarkers/>
          )}

          <StyledOnMapMove className="on-map-move">
            <Checkbox
              size="lg"
              colorScheme="primary"
              isChecked={searchOnMapMove}
              onChange={onChangeSearchOnMove}
            >
              <Text as="span" fontSize="md" fontWeight="semibold"><FormattedMessage {...messages.searchMapOnMove} /></Text>
            </Checkbox>
          </StyledOnMapMove>

          <StyledFullscreenControl className="fullscreen">
            <FullscreenControl />
          </StyledFullscreenControl>

          <StyledNav className="nav">
            <NavigationControl />
          </StyledNav>

        </MapGL>

      </GoogleApiWrapper>
    </StyledMapResults>


  );

};

export default ResultsMapComponent;

/*
query MyQuery {
  experiences {
    nodes {
      title(format: RAW)
      slug
      featuredImage {
        altText
        sourceUrl
      }
      seo {
        seoMetaDescription
        seoTitle
      }
      extraFields {
        listing {
          location {
            latitude
            longitude
          }
          mapItem {
            onPropertyMap
            onSearchMap
          }
          address
          description
          website
          telephone
          gallery {
            altText
            sourceUrl
          }
        }
        ratings {
          lastChecked
          reviewCount
          reviewSource
          starRating
        }
        social {
          facebook
          instagram
          tripadvisor
          twitter
          youtube
        }
      }
      experienceTypes {
        nodes {
          name
          extraFields {
            mapMarker {
              image {
                altText
                sourceUrl
              }
              height
              offsetLeft
              offsetTop
              width
            }
          }
        }
      }
    }
  }
}

 */
