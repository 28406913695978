import React from 'react';
import { connect } from 'react-redux';
import { window } from 'browser-monads';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import ResultsList from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/ResultsList/component';
import {
  makeSearchDidInvalidate,
  makeSelectSearchError,
  makeSelectIsFetching,
  makeSelectSearchData, makeSelectSearchFirstLoad,
} from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import { withPageContext } from '@rentivo/gatsby-core/src/context/PageContext';

const mapStateToProps = createSelector(
  makeSelectSearchFirstLoad(),
  makeSelectSearchData(),
  makeSelectIsFetching(),
  makeSearchDidInvalidate(),
  makeSelectSearchError(),
  (firstLoad, data, isFetching, didInvalidate, error) => ({
    firstLoad,
    data,
    isFetching,
    didInvalidate,
    error,
    showListItemSchema: true
  })
);

const enhance = compose(
  withPageContext(),
  connect(mapStateToProps)
);

ResultsList.defaultProps = {
  type: 'grid',
  grid: {},
  card: {},
  showViewButton: true,
  components: {
    Loader: undefined,
    ListItem: undefined,
    Meta: undefined,
    NoResults: undefined
  }
};

export default enhance(ResultsList);
