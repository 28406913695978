import React from 'react';
import { compose } from 'redux';
import PriceFilterPopover from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/PriceFilterPopover/component';
import withFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/withFilter';
import { defaultButtonProps } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/props';

const enhance = compose(
  withFilter('price'),
);

PriceFilterPopover.defaultProps = {
  className: 'filter-popover',
  filterComponent: null,
  button: defaultButtonProps
};

export default enhance(PriceFilterPopover);
