import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import CollectionTree from '@rentivo/gatsby-core/src/components/search/CollectionTree/component';
import {
  makeSelectSearchCollectionTree,
  makeSelectSearchFilters, selectSearchQuery
} from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import get from 'lodash/get';
import { withPageContext } from '@rentivo/gatsby-core/src/context/PageContext';

const mapStateToProps = createSelector(
  makeSelectSearchCollectionTree(),
  makeSelectSearchFilters('location'),
  selectSearchQuery,
  (collectionTreeProps, locationFilter, query) => {
    return {
      ...collectionTreeProps,
      placeName: (!query && collectionTreeProps.collectionName) ? collectionTreeProps.collectionName : get(locationFilter, 'value.0', null)
    };
  }
);

const enhance = compose(
  withPageContext(),
  connect(mapStateToProps),
);

export default enhance(CollectionTree);
