import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/pro-regular-svg-icons/faFrown';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';
import { faSadTear } from '@fortawesome/pro-solid-svg-icons/faSadTear';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/search/NoResults/messages';

const NoResultsComponent = () => {
  return (

    <Alert
      mt={3}
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon as={AwesomeIcon} icon={faSadTear} fontSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        <FormattedMessage {...messages.title}/>
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        <FormattedMessage {...messages.description}/>
      </AlertDescription>
    </Alert>
  );
};

export default NoResultsComponent;
