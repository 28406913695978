import { chakra } from '@chakra-ui/react';

export const StyledSearchMarkerPopup = chakra('div', {
  baseStyle: {
    w: '260px',
    '.property-card--image-gallery--nav-button': {
      w: '40px!important',
      h: '40px!important',
      lineHeight: '40px!important'
    }
  }
});
