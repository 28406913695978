import { objectMap } from '@rentivo/gatsby-core/src/utils/objects';
import { useBreakpointValue, useTheme } from '@chakra-ui/react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { selectSearchMapOpen } from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import { selectSearchMapIsOpenOnLoadConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { setMapOpen } from '@rentivo/gatsby-core/src/containers/SearchProvider/actions';

const getCardBreakpointProps = ({breakpoints, showViewButton}) => {
  return {
    borderRadius: {
      borderTopLeftRadius: 'md',
      borderTopRightRadius: objectMap(breakpoints, (b) => b.type === 'grid' ? 'md' : 0),
      borderBottomLeftRadius: objectMap(breakpoints, (b) => b.type === 'grid' ? 0 : 'md'),
    },
    wrapper: {
      direction: objectMap(breakpoints, (b) => b.type === 'grid' ? 'column' : 'row'),
    },
    meta: {
      pt: objectMap(breakpoints, (b) => b.type === 'grid' ? 2 : 4),
      h: objectMap(breakpoints, (b) => b.type === 'grid' ? '100%' : 'auto')
    },
    metaActions: {
      mt: objectMap(breakpoints, (b) => b.type === 'grid' ? 2 : 0),
      justify: showViewButton ? 'space-between' : objectMap(breakpoints, (b) => b.type === 'grid' ? 'space-between' : 'flex-end'),
      wrap: 'wrap'
    },
    cover: {
      width: objectMap(breakpoints, (b) => b.type === 'grid' ? '100%' : 330),
    }
  };
};

const defaultSearchConfig = {
  "defaultType": "list",
  "showViewButton": false,
  "layout": {
    "searchArea": {
      "mapOpen": {
        "base": {
          "colSpan": 24,
          "perRow": 1,
          "type": "grid"
        },
        "md": {
          "colSpan": 14,
          "perRow": 1,
          "type": "grid"
        },
        "lg": {
          "colSpan": 16,
          "perRow": 2,
          "type": "grid"
        },
        "xl": {
          "colSpan": 14,
          "perRow": 1,
          "type": "list"
        },
        "xxl": {
          "colSpan": 12,
          "perRow": 1,
          "type": "list"
        }
      },
      "mapClosed": {
        "base": {
          "perRow": 1,
          "type": "grid"
        },
        "md": {
          "perRow": 2,
          "type": "grid"
        },
        "lg": {
          "perRow": 3,
          "type": "grid"
        },
        "xl": {
          "perRow": 4,
          "type": "grid"
        },
        "xxl": {
          "perRow": 5,
          "type": "grid"
        }
      }
    }
  }
};

export const useSearchLayout = () => {
  const mounted = useRef(false);
  const dispatch = useDispatch();
  const { mapOpen, defaultMapOpen } = useSelector(createSelector(
    selectSearchMapOpen,
    selectSearchMapIsOpenOnLoadConfig,
    (mapOpen, defaultMapOpen) => ({
      mapOpen,
      defaultMapOpen
    })
  ));

  const theme = useTheme();
  const [isMapOpen, setInternalIsMapOpen] = useState(defaultMapOpen);

  const handleSetIsMapOpen = (open, updateState = true) => {
    if(updateState) dispatch(setMapOpen(open));
    setInternalIsMapOpen(open);
  };


  const props = useMemo(() => {
    const { barcelona: { search } } = theme;
    const useSearch = { ...defaultSearchConfig, ...search };
    const { defaultType: type, showViewButton = true, layout: { searchArea } } = useSearch;
    const mapArea = objectMap(searchArea.mapOpen, (obj) => ({colSpan: (obj.colSpan === 24) ? 24 : 24 - obj.colSpan}));
    const useSearchArea = (isMapOpen) ? searchArea.mapOpen : objectMap(searchArea.mapClosed, (obj) => ({...obj, colSpan: 24}));
    const cardResponsiveProps = getCardBreakpointProps({breakpoints: useSearchArea, showViewButton});
    const searchResultsColumns = objectMap(useSearchArea, (b) => b.perRow ? b.perRow : 1);
    const searchColSpan = objectMap(useSearchArea, (b) => isMapOpen ? b.colSpan : 24);
    const mapColSpan = objectMap(mapArea, (b) => isMapOpen ? b.colSpan : 24);
    const isMapDisplayed = objectMap(mapArea, (b) => b.colSpan !== 24);
    const mapPosition = objectMap(mapArea, (b) => b.colSpan === 24 ? 'absolute' : 'relative');
    //const desktopMapButtonDisplay = objectMap(mapArea, (b) => b.colSpan === 24 ? 'none' : 'block');
    //const mobileMapButtonDisplay = objectMap(mapArea, (b) => b.colSpan === 24 ? 'block' : 'none');

    const searchGridProps = {
      colSpan: searchColSpan
    };

    const searchResultsGridProps = {
      columns: searchResultsColumns
    };

    const mapGridProps = {
      colSpan: mapColSpan,
      position: mapPosition,
      top: 0,
      left:0,
      bottom:0,
      right:0
    };

    /*
    const desktopMapButtonProps = {
      display: desktopMapButtonDisplay
    };

    const mobileMapButtonProps = {
      display: mobileMapButtonDisplay
    };
    */

    const cardProps = {
      responsiveProps: cardResponsiveProps,
      showViewButton
    };

    return { searchGridProps, mapGridProps, searchResultsGridProps, isMapDisplayed, type, cardProps, showViewButton };

  }, [isMapOpen]);

  const isMapVisible = useBreakpointValue(props.isMapDisplayed);

  useEffect(() => {
    if(!mounted.current) {
      mounted.current = true;

      // Run on load...
      if(!isMapVisible && (isMapOpen || mapOpen)) {
        handleSetIsMapOpen(false, false);
      }

    } else {
      if(isMapVisible && mapOpen !== isMapOpen) {
        handleSetIsMapOpen(mapOpen, false);
      }
    }
  }, [mapOpen, isMapVisible, defaultMapOpen]);

  return { ...props, isMapOpen, setIsMapOpen: handleSetIsMapOpen };
};

export const useCanScrollMobile = () => {
  const [canScrollOnMobile, setCanScrollOnMobile] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if(currPos.y < 0 && !canScrollOnMobile) {
      setCanScrollOnMobile(true);
    }
  });

  return canScrollOnMobile;
};
