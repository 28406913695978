import React, { useCallback, useRef } from 'react'
import Paginate from '@rentivo/gatsby-core/src/components/ui/Paginate'
import get from 'lodash/get'
import { Box } from '@chakra-ui/react'

const PaginateResultsComponent = ({
  isMobileOnly,
  className,
  totalResults,
  resultsPerPage,
  filterOptions,
  setFilter,
  filter,
  pageContext,
}) => {
  const ref = useRef(null)
  const { path } = pageContext
  const defaultValue = get(filterOptions, 'defaultValue', 1)
  let value = get(filter, 'value', defaultValue)
  value = value > 0 ? value - 1 : 0

  const onChange = useCallback(
    ({ selected }) => {
      if (ref.current) {
        setFilter(selected + 1)
      } else {
        ref.current = true
      }
    },
    [ref]
  )

  if (totalResults > 10_000) {
    totalResults = 10_000 - resultsPerPage
  }

  const pageCount = Math.ceil(totalResults / resultsPerPage)

  return (
    <Box p={3} className={className}>
      <Paginate
        initialPage={value}
        forcePage={value}
        pageCount={pageCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        onPageChange={onChange}
        hrefBuilder={page => {
          return page === 1
            ? `${path}`
            : `${path}?${filterOptions.urlParam}=${page}`
        }}
      />
    </Box>
  )
}

export default PaginateResultsComponent
