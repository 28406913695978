import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import OpenGraph from '@rentivo/gatsby-core/src/components/schema/OpenGraph/component';
import {
  selectSiteSharingOgImageConfig, selectSiteSharingTwitterImageConfig,
  selectSiteWebsiteUrlConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { withRouter } from '@rentivo/gatsby-core/src/context/RouterContext';

const mapStateToProps = (state, { location: { origin, pathname }}) => createSelector(
  selectSiteWebsiteUrlConfig,
  selectSiteSharingOgImageConfig,
  selectSiteSharingTwitterImageConfig,
  (websiteUrl, defaultOgImage, defaultTwitterImage) => {
    return {
      websiteUrl,
      url: origin + pathname,
      defaultOgImage,
      defaultTwitterImage
    }
  }
);

const enhance = compose(
  withRouter,
  connect(mapStateToProps)
);


OpenGraph.defaultProps = {
  imageSmall: false
};

export default enhance(OpenGraph);
