import React from 'react';
import SearchComponent from '@rentivo/gatsby-theme-barcelona/src/templates/Search/component';
import { useMenusAndBlocksData } from '@rentivo/gatsby-theme-barcelona/src/hooks/usePageData';

const Search = (props) => {
  const menusAndBlocks = useMenusAndBlocksData(props);
  const { data, ...rest } = props;
  return (
    <SearchComponent {...menusAndBlocks} {...rest}/>
  );
};

export default Search;
