import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import SelectedFilters from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/SelectedFilters/component';
import {
  makeSelectSearchFilterLabels,
  makeSelectSearchFilters
} from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import { startRemoveFilter, startSetFilter } from '@rentivo/gatsby-core/src/containers/SearchProvider/actions';
import { withSearchProviderContext } from '@rentivo/gatsby-core/src/context/SearchProviderContext';

const mapStateToProps = createSelector(
  makeSelectSearchFilters(),
  makeSelectSearchFilterLabels(),
  (filters, filterLabels) => ({
    filters,
    filterLabels
  })
);

const mapDispatchToProps = (dispatch, { queryMap, tagValueMap }) => {
  return {
    removeFilter: (urlParam) => dispatch(startRemoveFilter({ queryMap, tagValueMap, urlParam }))
  };
};

const enhance = compose(
  withSearchProviderContext,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(SelectedFilters);
