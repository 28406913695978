import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PageFooter from '@rentivo/gatsby-core/src/components/search/PageFooter/component';
import {
  selectSiteContentVariablesConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import {
  makeSelectSearchCollectionPageHeader,
  makeSelectSearchFilters, selectSearchQuery
} from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import { withPageContext } from '@rentivo/gatsby-core/src/context/PageContext';

const mapStateToProps = createSelector(
  selectSiteContentVariablesConfig,
  makeSelectSearchCollectionPageHeader(),
  makeSelectSearchFilters('location'),
  selectSearchQuery,
  (siteVariables, pageHeaderProps, locationFilter, query) => {
    return {
      siteVariables,
      ...pageHeaderProps,
      placeName: (!query && pageHeaderProps.collectionName) ? pageHeaderProps.collectionName : get(locationFilter, 'value.0', null),
    };
  }
);

const enhance = compose(
  withPageContext(),
  connect(mapStateToProps),
);

export default enhance(PageFooter);
