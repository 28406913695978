import React from 'react';
import get from 'lodash/get';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/SortResults/messages';
import { DEFAULT_OPTIONS, SORT_RELEVANCE_ASC } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/SortResults/constants';
import { getFriendlyLycanName } from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage/utils';
import { Select, FormControl, FormLabel, Flex } from '@chakra-ui/react';
import { shortSize } from '@rentivo/gatsby-core/src/utils/transformers';

const SortResultsComponent = ({size, className, filterOptions, setFilter, filter, title, ...rest}) => {
  const { formatMessage } = useIntl();
  const options = get(filterOptions, 'options', DEFAULT_OPTIONS);
  const defaultValue = get(filterOptions, 'defaultValue', SORT_RELEVANCE_ASC);
  const value = get(filter, 'value', defaultValue);

  const handleChange = (e) => {
    if(e.target.value) {
      setFilter(e.target.value);
    } else {
      setFilter(defaultValue);
    }
  };

  const children = options.map(o => {
    return <option key={o.value} value={o.value}>{messages[o.value] ? formatMessage(messages[o.value]) : getFriendlyLycanName(o.value)}</option>;
  });

  return (
    <FormControl id="sort_by" className={className} width="auto">
      <Flex spacing={2} align="center" justify="flex-end" minW={{base: '230px'}}>
        {title !== undefined ? title : <FormLabel mb={0} whiteSpace="nowrap"><FormattedMessage {...messages.title}/></FormLabel>}
        <Select
          maxW="165px"
          fontSize="xs"
          borderRadius="md"
          id="sort_by"
          _hover={{cursor: 'pointer'}}
          size={shortSize(size)}
          placeholder={formatMessage(messages.title)}
          value={value}
          onChange={handleChange}
        >
          {children}
        </Select>
      </Flex>
    </FormControl>
  );
};

export default SortResultsComponent;
