import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/PriceFilterPopover/messages';
import PopoverButton from '@rentivo/gatsby-core/src/components/ui/PopoverButton';
import loadable from '@loadable/component';

const Component = loadable(() => import('@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/PriceFilter'));

const PriceFilterPopoverComponent = ({filterComponent, button, filterOptions, filter, styleProps = {}, ...rest}) => {
  const [visible, setVisible] = useState(false);
  const value = (filter && filter.value) ? filter.value : filterOptions.defaultValue;

  return (
    <Box {...styleProps}>
      <PopoverButton
        component={filterComponent ? filterComponent : Component}
        visible={visible}
        button={button}
        onVisibleChange={() => setVisible(!visible)}
        buttonLabel={<FormattedMessage {...messages.setLabel} values={{value}}/>}
      />
    </Box>
);
};

export default PriceFilterPopoverComponent;

