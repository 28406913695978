import React from 'react';
import Price from '@rentivo/gatsby-core/src/components/property/Price';
import {
  StyledSearchMarkerPin, StyledSearchMarkerPinArrow,
  StyledSearchMarkerPinContent
} from '@rentivo/gatsby-core/src/components/map/SearchMarker/styles';

const SearchMarkerComponent = ({property, onMarkerClick}) => {
  if(!property) return null;
  const { id } = property;

  return (
    <StyledSearchMarkerPin id={`search-marker-${id}`} onClick={() => onMarkerClick(property)}>
      <StyledSearchMarkerPinContent className="map-pin-content">
        <Price shortened={true}/>
      </StyledSearchMarkerPinContent>
      <StyledSearchMarkerPinArrow className="map-pin-arrow"/>
    </StyledSearchMarkerPin>
  );

};

export default SearchMarkerComponent;
