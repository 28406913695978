import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import LosFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LosFilter/component';
import withFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/withFilter';
import { query } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LosFilter/query';
import { withResponsive } from '@rentivo/gatsby-core/src/context/ResponsiveContext';
import {tagValue} from './tag';

const enhance = compose(
  withFilter('los', query, tagValue),
  withFilter('guests', null),
  withFilter('availability', null),
  withResponsive
);

LosFilter.defaultProps = {
  className: 'filter',
  runQuery: false
};

LosFilter.propTypes = {
  className: PropTypes.string,
  runQuery: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ])
};

export default enhance(LosFilter);
