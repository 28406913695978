import { chakra } from '@chakra-ui/react';

export const StyledSearchMarkerPin = chakra('div', {
  baseStyle: {
    position: 'relative',
    h: '31px',
    overflow: 'hidden',
    '&.active-pin': {
      '.map-pin-arrow:after, .map-pin-content': {
        bg: 'primary.500',
        color: 'white'
      }
    }
  }
});

export const StyledSearchMarkerPinContent = chakra('div', {
  baseStyle: {
    maxW: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    bg: 'white',
    px: '4px',
    py: '1px',
    position: 'relative',
    fontWeight: 'medium',
    borderRadius: '3px',
    boxShadow: '1px 2px 1px rgba(0,0,0,0.15)',
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer'
    },
    '.price': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'block',
      textOverflow: 'ellipsis',
      fontSize: '14px'
    }
  }
});

export const StyledSearchMarkerPinArrow = chakra('div', {
  baseStyle: {
    zIndex: 2,
    position: 'absolute',
    w: '16px',
    h: '8px',
    overflow: 'hidden',
    bg: 'transparent',
    left: '4px',
    bottom: '0px',
    '&:after': {
      content: '""',
      top: '-4px',
      left: '4px',
      position: 'absolute',
      w: '8px',
      h: '8px',
      bg: 'white',
      transform: 'rotate(45deg)',
      boxShadow: '1px 2px 1px rgba(0, 0, 0, 0.15)'
    }
  }
});
