import { chakra } from '@chakra-ui/react';
import ResultsMap from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/ResultsMap';
import PaginateResults from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/PaginateResults';

export const StyledSearchResultsMap = chakra(ResultsMap, {
  baseStyle: {
    position: 'absolute',
    inset: '0px',
    zIndex: 999
  }
});

export const StyledPaginateResults = chakra(PaginateResults, {
  baseStyle: {
    py: 6,
    display: 'flex',
    justifyContent: 'center'
  }
});
