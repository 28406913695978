import React, { useState } from 'react';
import { faLongArrowLeft } from '@fortawesome/pro-duotone-svg-icons/faLongArrowLeft';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/search/CollectionTree/messages';
import LocaleLink from '@rentivo/gatsby-core/src/components/navigation/LocaleLink';
import Icon from '@rentivo/gatsby-core/src/components/ui/Icon';
import { getCollectionNameAndUri } from '@rentivo/gatsby-core/src/components/search/CollectionTree/utils';
import { areStringsSimilar } from '@rentivo/gatsby-core/src/utils/strings';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { selectRoutePathsConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { Box, Flex, Text, Link } from '@chakra-ui/react';

const itemsToShow = 12;

const CollectionTreeParent = ({parent}) => {
  const { name, uri, slug } = getCollectionNameAndUri(parent);
  return <TreeItem uri={uri} slug={slug}><Icon icon={faLongArrowLeft}/> <FormattedMessage {...messages.backLabel} values={{name: name}}/></TreeItem>
};

const CollectionTreeItems = ({items, initChildren, collectionName}) => {
  return (
    <>
      {items.map((item, i) => <CollectionTreeItem i={i} collectionName={collectionName} initChildren={initChildren} key={item.id} item={item}/>)}
    </>
  );
};

const CollectionTreeItem = ({item, initChildren, collectionName, i}) => {
  const { name, uri, slug } = getCollectionNameAndUri(item);
  return (
    <TreeItem uri={uri} slug={slug} i={i} initChildren={initChildren} collectionName={collectionName}>{name}</TreeItem>
  );
};


const routePathSelector = createSelector(
  selectRoutePathsConfig,
  (routePaths) => routePaths
);

const TreeItem = ({children, uri, slug, type, handleOnClick, initChildren, collectionName, i}) => {
  const routePaths = useSelector(routePathSelector);

  let typeClassName = '';
  if(type && type === 'button') typeClassName = 'button-link';
  if(uri) return (
    <Box mr={4} className={classNames({double: (initChildren && i === 0)})}>
      {initChildren && i === 0 && <Text as="span" fontWeight="semibold" mr={2}><FormattedMessage {...messages.title} values={{name: collectionName}}/>: </Text>}
      <LocaleLink layerStyle="impactLink" to={`/${routePaths.ROUTE_BASE_SEARCH}/${slug}/`}>{children}</LocaleLink>
    </Box>
  );
  return (
    <Box className={classNames(typeClassName, {double: (initChildren && i === 0)})} mr={4}>
      {initChildren && i === 0 && <Text as="span" fontWeight="semibold" mr={2}><FormattedMessage {...messages.title} values={{name: collectionName}}/>: </Text>}

      {uri ? (
        <LocaleLink layerStyle="impactLink" to={`/${routePaths.ROUTE_BASE_SEARCH}/${slug}/`}>{children}</LocaleLink>
      ) : (
        <Link as="span" layerStyle="impactLink" onClick={handleOnClick}>{children}</Link>
      )}
    </Box>
  )
};

TreeItem.defaultProps = {
  handleOnClick: () => null,
  type: ''
};

const CollectionTreeComponent = (props) => {
  const { showTree, placeName, collectionName, children, parents, className, ...rest } = props;
  const [moreCollectionsVisible, setMoreCollectionsVisible] = useState(false);

  if(!areStringsSimilar(placeName, collectionName)) {
    return null;
  }

  if(!showTree || (!children.length && !parents.length)) {
    return null;
  }

  const parent = (parents.length) ? parents[0] : null;
  const initChildren = (children.length) ? children.filter((c, i) => i < itemsToShow) : null;
  const moreChildren = (children.length > 12) ? children.filter((c, i) => i >= itemsToShow) : null;

  return (
    <div className={className}>
      {(initChildren || parent) && (
        <Box mb={6} {...rest}>
          <Flex wrap="wrap">
            {parent && <CollectionTreeParent parent={parent}/>}
            {initChildren && <CollectionTreeItems initChildren={true} items={initChildren} collectionName={collectionName}/>}
            {moreChildren && (
              <>
                <TreeItem
                  handleOnClick={() => setMoreCollectionsVisible(!moreCollectionsVisible)}
                  type="button"
                >
                  {moreCollectionsVisible ? <FormattedMessage {...messages.showLessLabel}/> : <FormattedMessage {...messages.showMoreLabel}/>}
                </TreeItem>
                {moreCollectionsVisible && <CollectionTreeItems collectionName={collectionName} items={moreChildren}/>}
              </>
            )}
          </Flex>
        </Box>
      )}
    </div>
  );
};

//{initChildren && <h4><FormattedMessage {...messages.title} values={{name: collectionName}}/>:</h4>}

export default CollectionTreeComponent;
