import React from 'react';
import FilterTag from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/SelectedFilters/components/FilterTag';
import { HStack } from '@chakra-ui/react';

const SelectedFiltersComponent = ({filters, removeFilter, className, ...rest}) => {
  return (
    <HStack spacing={2} mb={2} className={className} {...rest}>
      {filters.map(filter => <FilterTag removeFilter={removeFilter} key={filter.urlParam} filter={filter}/>)}
    </HStack>
  );
};

export default SelectedFiltersComponent;
