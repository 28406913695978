import { useBreakpointValue } from '@chakra-ui/react';

export const useDevice = () => {
  return useBreakpointValue({ base: 'mobile', md: 'tablet', lg: 'desktop' });
};

export const useIsMobile = () => {
  const device = useDevice();
  return device === 'mobile' || device === 'tablet';
};

export const useIsMobileOnly = () => {
  const device = useDevice();
  return device === 'mobile';
};

export const useIsTablet = () => {
  const device = useDevice();
  return device === 'tablet';
};

export const useIsDesktop = () => {
  const device = useDevice();
  return device === 'desktop';
};

export const useIsDesktopOrTablet = () => {
  const device = useDevice();
  return device === 'desktop' || device === 'tablet';
};
