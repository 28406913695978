import React from 'react';
import { Box } from '@chakra-ui/react';
import { replaceStringWithVariable } from '@rentivo/gatsby-core/src/utils/strings';
import dayjs from 'dayjs';
import RenderHtml from '@rentivo/gatsby-core/src/components/generic/RenderHtml';

const PageFooterComponent = ({
  siteVariables,
  collectionName,
  collectionContent,
  placeName,
  className
}) => {
  const now = dayjs();
  const next = now.add(1, 'year');
  const currentYear = now.format('YYYY');
  const nextYear = next.format('YYYY');
  const variables = { ...siteVariables, placeName, collectionName, currentYear, nextYear };

  let pageContent = null;

  if(placeName) {
    if(collectionName) {
      pageContent = (collectionContent) ? replaceStringWithVariable(collectionContent, variables) : null;
    }
  }

  if(!pageContent) return null;

  return (
    <Box className={className} mb={6}>
      <RenderHtml html={pageContent}/>
    </Box>
  );
};

export default PageFooterComponent;
