import get from 'lodash/get';
import { trimLastSlash } from '@rentivo/gatsby-core/src/utils/strings';

export const getCollectionNameAndUri = (collection) => {
  const name = get(collection, 'collection.name', get(collection, 'title', 'Unknown'));
  const uri = trimLastSlash(get(collection, 'uri', '/'));
  const slug = trimLastSlash(get(collection, 'slug', '/'));

  return {
    name,
    uri,
    slug
  }
};
