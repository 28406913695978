import React, { useRef, useState, memo } from 'react';
//import ScrollContainer from 'react-indiana-drag-scroll';
import { Box, HStack } from '@chakra-ui/react';
import LocationFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter';
import AvailabilityFilterPopover from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityFilterPopover';
import GuestsFilterPopover from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilterPopover';
import PriceFilterPopover from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/PriceFilterPopover';
import ExtraFiltersModalButton from '@rentivo/gatsby-theme-barcelona/src/templates/Search/components/ExtraFiltersModalButton';
import useDimensions from '@rentivo/gatsby-core/src/hooks/useDimensions';
import debounce from 'lodash/debounce';
import { useIsDesktop, useIsMobile } from '@rentivo/gatsby-core/src/hooks/useDevice';


const filterButtonProps = {
  size: 'md',
  h: { base: '36px', lg: '40px' },
  type: 'default',
  ghost: false,
  block: false
};

const filterButtonPropsSmall = {
  size: 'xs',
  type: 'muted',
  block: false,
  border: 0,
  forceButtonIcon: true,
  iconStyle: { marginLeft: '-0.25em' }
};

const PrimaryFilters = () => {

  const filtersScrollContainerRef = useRef(null);
  const [filtersScrollRef, { scrollWidth: filtersWidth }] = useDimensions();
  const [filtersContentRef, { scrollWidth: filtersContentWidth }] = useDimensions();
  const [filtersScrollAmount, setFiltersScrollAmount] = useState(0);
  const debouncedSetFiltersScrollAmount = useRef(debounce(data => setFiltersScrollAmount(data), 500)).current;
  const filtersScrollableAmount = filtersContentWidth - filtersWidth - 24;
  const isFilterArrowVisible = (filtersScrollAmount < filtersScrollableAmount);

  const [extraFiltersOpen, setExtraFiltersOpen] = useState(false);
  const [isLocationFocused, setIsLocationFocused] = useState(false);
  const debouncedSetIsLocationFocused = useRef(debounce(data => setIsLocationFocused(data), 200)).current;

  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  return (
    <Box
      className="primary-filters"
      position="relative"
      zIndex={3}
      borderBottom="1px solid"
      borderColor="gray.200"
      ref={filtersScrollRef}
      css={{
        '.indiana-scroll-container': {
          overflow: isLocationFocused ? 'visible!important' : 'hidden'
        }
      }}
    >
      <Box
        ref={filtersContentRef}
        pt={3}
        pb={{base: 2, lg: 3}}
        px={5}
        //px={{base: 3, md: 5}}
        overflowX="auto"
      >
        <HStack spacing={2} w={{base: '100%', lg: 'auto'}}>
          <LocationFilter
            title={null}
            onSuggestSelect={() => debouncedSetIsLocationFocused(false)}
            onBlur={() => debouncedSetIsLocationFocused(false)}
            onFocus={() => {
              debouncedSetIsLocationFocused(true);
              setExtraFiltersOpen(false);
            }}
            onUpdateSuggests={(suggests) => {
              if(suggests.length) return setIsLocationFocused(true);
              return debouncedSetIsLocationFocused(false);
            }}
            styleProps={{
              width: { base: '100%', lg: 'auto' }
            }}
            button={{...filterButtonProps, width: {
                base: "100%", // 210px
                lg: "260px"
              }}}
            googlePlaces={{width: {
                base: "100%", // 225px
                lg: "300px"
              }}}
          />
          <AvailabilityFilterPopover button={filterButtonProps} styleProps={{display: { base: 'none', lg: 'block' }}}/>
          <GuestsFilterPopover button={filterButtonProps} styleProps={{display: { base: 'none', lg: 'block' }}}/>
          <PriceFilterPopover button={filterButtonProps} styleProps={{display: { base: 'none', lg: 'block' }}}/>
          <ExtraFiltersModalButton
            styleProps={{display: { base: 'none', lg: 'block' }}}
            filterButtonProps={filterButtonProps}
            extraFiltersOpen={extraFiltersOpen}
            setExtraFiltersOpen={setExtraFiltersOpen}
          />
        </HStack>
        <HStack mt={1} mx={-2} spacing="0px" display={{ base: 'flex', lg: 'none' }}>
          <AvailabilityFilterPopover button={filterButtonPropsSmall} />
          <GuestsFilterPopover button={filterButtonPropsSmall} />
          <ExtraFiltersModalButton
            styleProps={{display: { base: 'flex', lg: 'none' }}}
            filterButtonProps={filterButtonPropsSmall}
            extraFiltersOpen={extraFiltersOpen}
            setExtraFiltersOpen={setExtraFiltersOpen}
          />
        </HStack>
      </Box>
    </Box>
  );
};

/*
<ScrollContainer
        vertical={false}
        horizontal={true}
        hideScrollbars={true}
        innerRef={filtersScrollContainerRef}
        onScroll={debouncedSetFiltersScrollAmount}
      >
        <Flex
          ref={filtersContentRef}
          pt={3}
          pb={3}
          pr={5}
          pl={5}
        >
          <HStack spacing={2} w={{base: '100%', lg: 'auto'}}>
            <LocationFilter
              title={null}
              onSuggestSelect={() => debouncedSetIsLocationFocused(false)}
              onBlur={() => debouncedSetIsLocationFocused(false)}
              onFocus={() => {
                debouncedSetIsLocationFocused(true);
                setExtraFiltersOpen(false);
              }}
              onUpdateSuggests={(suggests) => {
                if(suggests.length) return setIsLocationFocused(true);
                return debouncedSetIsLocationFocused(false);
              }}
              styleProps={{
                width: { base: '100%', lg: 'auto' }
              }}
              button={{...filterButtonProps, width: {
                base: "100%", // 210px
                lg: "260px"
              }}}
              googlePlaces={{width: {
                base: "100%", // 225px
                lg: "300px"
              }}}
            />
           {(isDesktop) && (
              <>
                <AvailabilityFilterPopover button={filterButtonProps} styleProps={{display: { base: 'none', lg: 'block' }}}/>
                <GuestsFilterPopover button={filterButtonProps} styleProps={{display: { base: 'none', lg: 'block' }}}/>
                <PriceFilterPopover button={filterButtonProps} styleProps={{display: { base: 'none', lg: 'block' }}}/>
                <ExtraFiltersModalButton
                  styleProps={{
                    pr: { base: "64px", xl: 0 },
                    display: { base: 'none', lg: 'block' }
                  }}
                  filterButtonProps={filterButtonProps}
                  extraFiltersOpen={extraFiltersOpen}
                  setExtraFiltersOpen={setExtraFiltersOpen}
                />
              </>
            )}
            {(isMobile) && (
              <>
                <ExtraFiltersModalButton
                  styleProps={{
                    ml: 'auto!important',
                    pr: 0,
                    pl: 2
                  }}
                  filterButtonProps={filterButtonProps}
                  extraFiltersOpen={extraFiltersOpen}
                  setExtraFiltersOpen={setExtraFiltersOpen}
                />
              </>
            )}

          </HStack>
        </Flex>
      </ScrollContainer>
      <Flex
        opacity={isFilterArrowVisible ? '1' : '0'}
        position="absolute"
        transition="opacity .25s ease-in-out"
        align="center"
        justify="flex-end"
        pr={3}
        color="gray.300"

        top="0"
        right="0"
        bg="white"
        height="100%"
        width="100px"
        style={{background: "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%)"}}
        pointerEvents="none"
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </Flex>
 */

export default memo(PrimaryFilters);
