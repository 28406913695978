import React, { useRef } from 'react'
import classNames from 'classnames'
import { SimpleGrid, Box } from '@chakra-ui/react'
import { PropertyComponent } from '@rentivo/gatsby-core/src/context/PropertyContext'
import DefaultLoader from '@rentivo/gatsby-core/src/components/generic/Loader'
import DefaultNoResults from '@rentivo/gatsby-core/src/components/search/NoResults'
import ResultsMeta from '@rentivo/gatsby-core/src/components/search/ResultsMeta'
import ResultsListItem from '@rentivo/gatsby-core/src/components/property/ChakraPropertyCard'
import {
  handleMouseEnter,
  handleMouseLeave,
} from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/ResultsList/utils'
import { useComponents } from '@rentivo/gatsby-core/src/hooks/useComponents'

const ResultsListComponent = ({
  type,
  showViewButton,
  firstLoad,
  grid,
  card,
  showListItemSchema,
  data,
  isFetching,
  didInvalidate,
  error,
  components,
  className,
}) => {
  const listingContainer = useRef(null)
  const { hits } = data
  const { Loader, ListItem, Meta, NoResults } = useComponents(components, {
    Loader: DefaultLoader,
    ListItem: ResultsListItem,
    Meta: ResultsMeta,
    NoResults: DefaultNoResults,
  })

  const isGrid = type === 'grid'

  return (
    <div className={classNames('results-list', className)}>
      <>
        {hits.length ? (
          <div className="results-list--container">
            {Meta && <Meta data={data} />}
            <Box
              mt={2}
              itemScope={showListItemSchema}
              itemType={showListItemSchema && 'https://schema.org/ItemList'}
            >
              <SimpleGrid
                ref={listingContainer}
                spacing="16px"
                columns={isGrid ? [1, 2, 2, 3, 3, 4] : [1, 2, 1]}
                {...grid}
                className="results-list--list"
              >
                {hits.map((hit, i) => (
                  <PropertyComponent
                    onMouseEnter={() => handleMouseEnter(hit.id)}
                    onMouseLeave={() => handleMouseLeave(hit.id)}
                    component={ListItem}
                    position={1 + i}
                    property={hit}
                    type={type}
                    containerRef={listingContainer}
                    showListItemSchema={showListItemSchema}
                    showViewButton={showViewButton}
                    key={`list-item-${hit.id}`}
                    loading={isFetching}
                    {...card}
                  />
                ))}
              </SimpleGrid>
            </Box>
          </div>
        ) : (
          <>{isFetching ? <Loader /> : <>{!firstLoad && <NoResults />}</>}</>
        )}
      </>
    </div>
  )
}

export default ResultsListComponent

// <PropertyContext.Provider value={hit} key={hit.id}>
/*

{isFetching ? (
  <Loader/>
) : (
  <>
    {hits.length ? (
      <PropertyContext.Provider value={hits}>
        <div className="results-list--container">
          <Meta data={data}/>
          <ul className="results-list--list">
            {hits.map(hit => <PropertyListItem component={ListItem} hit={hit} key={`list-item-${hit.id}`} loading={isFetching}/>)}
          </ul>
        </div>
      </PropertyContext.Provider>
    ) : (
      <NoResults/>
    )}
  </>
)}

 */
