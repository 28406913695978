import React from 'react';
import { get, isEqual } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PageHeader from '@rentivo/gatsby-core/src/components/search/PageHeader/component';
import {
  selectSiteContentVariablesConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import {
  makeSelectSearchCollectionPageHeader,
  makeSelectSearchFilters, selectDefaultSearchFilters, selectSearchFilterByKey, selectSearchFilters, selectSearchQuery
} from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import { withPageContext } from '@rentivo/gatsby-core/src/context/PageContext';

// Grab current collections filters.

const mapStateToProps = (state, ownProps) => createSelector(
  selectSiteContentVariablesConfig,
  selectSearchFilters,
  selectDefaultSearchFilters,
  makeSelectSearchCollectionPageHeader(),
  selectSearchQuery,
  (siteVariables, filters, defaultFilters, pageHeaderProps, query) => {
    const locationFilter = selectSearchFilterByKey(filters, 'location');

    // If one of the default filters different to the main search filters.
    let isDifferent = false;
    defaultFilters.forEach(df => {
      const doesExistInFilters = filters.filter(f => f.id === df.id);
      const matchingFilter = (doesExistInFilters && doesExistInFilters.length) ? doesExistInFilters[0] : null;
      if(matchingFilter) {
        if(!isEqual(matchingFilter.value, df.value)) {
          isDifferent = true;
        }
      } else {
        isDifferent = true;
      }
    });

    // Check if collection filters no longer exist in search filters
    //let hasDifferentFilters

    return {
      siteVariables,
      isDifferent,
      ...pageHeaderProps,
      placeName: (!query && pageHeaderProps.collectionName) ? pageHeaderProps.collectionName : get(locationFilter, 'value.0', null),
    };
  }
);

const enhance = compose(
  withPageContext(),
  connect(mapStateToProps),
);

PageHeader.defaultProps = {
  showContent: false
};

export default enhance(PageHeader);
