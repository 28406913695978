import { defineMessages } from 'react-intl';

export const scope = 'components.search.ResultsMeta';

export default defineMessages({
  statsTitle: {
    id: `${scope}.stats.title`,
    defaultMessage: 'Found {total} results in {took}ms'
  }
});
