import { defineMessages } from 'react-intl';

export const scope = 'components.search.CollectionTree';

export default defineMessages({
  backLabel: {
    id: `${scope}.back.label`,
    defaultMessage: 'Back to {name}'
  },
  showMoreLabel: {
    id: `${scope}.showMore.label`,
    defaultMessage: 'Show more'
  },
  showLessLabel: {
    id: `${scope}.showLess.label`,
    defaultMessage: 'Show less'
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Collections in {name}'
  }
});
