import { defineMessages } from 'react-intl';

export const scope = 'containers.SearchProvider.results.ResultsMap';

export default defineMessages({
  searchMapOnMove: {
    id: `${scope}.searchMapOnMove.label`,
    defaultMessage: 'Search on move'
  },
  loadingPlaceName: {
    id: `${scope}.loadingPlaceName.label`,
    defaultMessage: 'Loading...'
  },
  mapViewPlaceName: {
    id: `${scope}.mapViewPlaceName.label`,
    defaultMessage: 'map view'
  },
  unknownPlaceName: {
    id: `${scope}.unknownPlaceName.label`,
    defaultMessage: 'Custom location'
  }
});
