import React from 'react';
import { StyledSearchMarkerPopup } from '@rentivo/gatsby-core/src/components/map/SearchMarkerPopup/styles';
import PropertyCard from '@rentivo/gatsby-core/src/components/property/ChakraPropertyCard';

const MemoPropertyCard = React.memo(PropertyCard);

const SearchMarkerPopupComponent = () => {
  return (
    <StyledSearchMarkerPopup className="search-marker-popup">
      <MemoPropertyCard inMap={true} components={{discounts: null, averageRating: null}}/>
    </StyledSearchMarkerPopup>
  );
};

export default React.memo(SearchMarkerPopupComponent);
