import React from 'react'
import { Heading, Text } from '@chakra-ui/react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { messagesToFormattedObject } from '@rentivo/gatsby-core/src/components/search/PageHeader/utils'
import {
  StyledPageHeader,
  StyledPageHeaderContent,
} from '@rentivo/gatsby-core/src/components/search/PageHeader/styles'
import { replaceStringWithVariable } from '@rentivo/gatsby-core/src/utils/strings'
import OpenGraph from '@rentivo/gatsby-core/src/components/schema/OpenGraph'
import dayjs from 'dayjs'
import { window } from 'browser-monads'

const PageHeaderComponent = ({
  siteVariables,
  isDifferent,
  collectionName,
  collectionContent,
  useCollectionRules,
  collectionPageTitle,
  collectionPageSubtitle,
  collectionSeoTitle,
  collectionSeoDesc,
  collectionSlug,
  showContent,
  placeName,
  className,
}) => {
  const { formatMessage } = useIntl()
  const now = dayjs()
  const next = now.add(1, 'year')
  const currentYear = now.format('YYYY')
  const nextYear = next.format('YYYY')

  const variables = {
    ...siteVariables,
    placeName: placeName,
    collectionName: collectionName,
    currentYear,
    nextYear,
  }
  const content = messagesToFormattedObject(formatMessage, variables)

  let pageTitle = content.defaultPageTitle
  let pageSubtitle = content.defaultPageSubtitle
  let pageContent = null
  let seoTitle = content.defaultSeoTitle
  let seoDesc = content.defaultSeoDesc

  if (!isDifferent) {
    if (collectionName) {
      if (useCollectionRules) {
        pageTitle = replaceStringWithVariable(
          content.collectionPageTitle,
          variables
        )
        pageSubtitle = replaceStringWithVariable(
          content.collectionPageSubtitle,
          variables
        )
        seoTitle = replaceStringWithVariable(
          content.collectionSeoTitle,
          variables
        )
        seoDesc = replaceStringWithVariable(
          content.collectionSeoDesc,
          variables
        )
      } else {
        pageTitle = replaceStringWithVariable(collectionPageTitle, variables)
        pageSubtitle = replaceStringWithVariable(
          collectionPageSubtitle,
          variables
        )
        seoTitle = collectionSeoTitle
          ? replaceStringWithVariable(collectionSeoTitle, variables)
          : replaceStringWithVariable(collectionPageTitle, variables)
        seoDesc = collectionSeoDesc
          ? replaceStringWithVariable(collectionSeoDesc, variables)
          : replaceStringWithVariable(collectionPageSubtitle)
      }

      pageContent =
        collectionContent && showContent
          ? replaceStringWithVariable(collectionContent, variables)
          : null
    }
  }

  if (placeName) {
    if (placeName !== collectionName) {
      // Need to diff between place & collection...
      // Replace variables...
      pageTitle = replaceStringWithVariable(content.placePageTitle, variables)
      pageSubtitle = replaceStringWithVariable(
        content.placePageSubtitle,
        variables
      )
      seoTitle = replaceStringWithVariable(content.placeSeoTitle, variables)
      seoDesc = replaceStringWithVariable(content.placeSeoDesc, variables)
    }
  }

  const urlPieces = [
    window.location.protocol,
    '//',
    window.location.host,
    window.location.pathname,
  ]
  const url = urlPieces.join('')

  return (
    <StyledPageHeader className={className}>
      <Helmet>
        {seoTitle && <title>{seoTitle}</title>}
        {seoDesc && <meta name="description" content={seoDesc} />}
        {window.location && <link rel="canonical" href={url} />}
        <body
          className={`page-search ${
            collectionSlug ? `page-search-${collectionSlug}` : ``
          }`}
        />
      </Helmet>
      <OpenGraph
        title={pageTitle ? pageTitle : seoTitle}
        description={pageSubtitle ? pageSubtitle : seoDesc}
      />
      {pageTitle && (
        <Heading as="h1" size="md">
          {pageTitle}
        </Heading>
      )}
      {pageSubtitle && <Text variant="light">{pageSubtitle}</Text>}
      {pageContent && (
        <StyledPageHeaderContent
          dangerouslySetInnerHTML={{ __html: pageContent }}
        />
      )}
    </StyledPageHeader>
  )
}

export default PageHeaderComponent
