import messages, { messagesObject } from '@rentivo/gatsby-core/src/components/search/PageHeader/messages';
import { parseFormattedMessage, replaceStringWithVariable } from '@rentivo/gatsby-core/src/utils/strings';

export const messagesToFormattedObject = (formatMessage, variables) => {
  let final = {};
  for (let [key] of Object.entries(messagesObject)) {
    final[key] = parseFormattedMessage(formatMessage(messages[key], variables))
  }
  return final;
};

export const getCollection = ({useCollectionRules, content, variables, collectionPageTitle, collectionPageSubtitle, collectionSeoTitle, collectionSeoDesc, collectionContent, showContent}) => {
  let pageTitle = replaceStringWithVariable(collectionPageTitle, variables);
  let pageSubtitle = replaceStringWithVariable(collectionPageSubtitle, variables);
  let seoTitle = collectionSeoTitle ? replaceStringWithVariable(collectionSeoTitle, variables) : replaceStringWithVariable(collectionPageTitle, variables);
  let seoDesc = collectionSeoDesc ? replaceStringWithVariable(collectionSeoDesc, variables) : replaceStringWithVariable(collectionPageSubtitle);

  if(useCollectionRules) {
    pageTitle = replaceStringWithVariable(content.collectionPageTitle, variables);
    pageSubtitle = replaceStringWithVariable(content.collectionPageSubtitle, variables);
    seoTitle = replaceStringWithVariable(content.collectionSeoTitle, variables);
    seoDesc = replaceStringWithVariable(content.collectionSeoDesc, variables);
  }

  const pageContent = (collectionContent && showContent) ? replaceStringWithVariable(collectionContent, variables) : null;

  return {
    pageTitle,
    pageSubtitle,
    seoTitle,
    seoDesc,
    pageContent
  }
};
