import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-theme-barcelona/src/templates/Search/components/ExtraFiltersModalButton/messages';
import loadable from '@loadable/component';
import PopoverButton from '@rentivo/gatsby-core/src/components/ui/PopoverButton';
import { Box } from '@chakra-ui/react';

const ExtraFilters = loadable(() => import('@rentivo/gatsby-theme-barcelona/src/templates/Search/components/ExtraFilters'));


const ExtraFiltersModalButtonComponent = ({onMouseOver, extraFiltersOpen, setExtraFiltersOpen, filterButtonProps, styleProps = {}, ...rest}) => {
  return (
    <Box {...styleProps}>
      <PopoverButton
        title={<FormattedMessage {...messages.moreFiltersButtonText}/>}
        component={ExtraFilters}
        button={filterButtonProps}
        //componentProps={{width: nestedSelectorWidth, options: collections, onChange: handleOnChange, title: placeholder, initialValueId}}
        buttonLabel={<FormattedMessage {...messages.moreFiltersButtonText}/>}
        useModal={true}
      />
    </Box>
  );
};

export default ExtraFiltersModalButtonComponent;
