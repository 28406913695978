import React from 'react';
import classNames from 'classnames';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import get from 'lodash/get';
import { Box, Tooltip, Switch, FormControl, FormLabel } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Info from '@rentivo/gatsby-core/src/components/ui/Info';

const LosFilterComponent = ({className, filters, ...rest}) => {
  const { filter, filterOptions, setFilter } = filters['los'];
  if(!filterOptions.active || (!filters['guests'].filter || !filters['availability'].filter) || !filterOptions.showFilter) return null;

  const onChange = (e) => {
    setFilter(e.target.checked ? 'yes' : 'no');
  };

  const defaultValue = get(filterOptions, 'defaultValue', null);
  const value = get(filter, 'value', defaultValue);

  return (
    <Box mb={3} className={classNames(className)} {...rest}>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="los-switch" mb="0">
          <FormattedMessage {...messages.title}/>
          <Info ml={1} tooltip={{hasArrow: true, fontSize: 'md'}} title={value === 'yes' ? <FormattedMessage {...messages.tooltipOn}/> : <FormattedMessage {...messages.tooltipOff}/>}/>
        </FormLabel>
        <Switch
          id="los-switch"
          colorScheme="primary"
          isChecked={value === 'yes'}
          onChange={onChange}
        />
      </FormControl>
    </Box>
  );
};

export default LosFilterComponent;
