import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PaginateResults from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/PaginateResults/component';
import withFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/withFilter';
import { query } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/PaginateResults/query';
import { makeSelectSearchHitsTotal } from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import {
  makeSelectSearchResultsPerPageConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { withPageContext } from '@rentivo/gatsby-core/src/context/PageContext';
import { withResponsive } from '@rentivo/gatsby-core/src/context/ResponsiveContext';

const mapStateToProps = createSelector(
  makeSelectSearchHitsTotal(),
  makeSelectSearchResultsPerPageConfig(),
  (totalResults, resultsPerPage) => ({
    totalResults,
    resultsPerPage
  })
);

const enhance = compose(
  withPageContext(),
  connect(mapStateToProps),
  withFilter('page', query),
  withResponsive
);

PaginateResults.defaultProps = {
  className: 'results-pagination',
  size: 'base'
};

PaginateResults.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'base', 'lg'])
};

export default enhance(PaginateResults);
