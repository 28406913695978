import { defineMessages } from 'react-intl';

export const scope = 'containers.SearchProvider.filters';

export default defineMessages({
  bedrooms: {
    id: `${scope}.bedrooms.filterLabel`,
    defaultMessage: 'Bedrooms'
  },
  guests: {
    id: `${scope}.guests.filterLabel`,
    defaultMessage: 'Guests'
  },
  adults: {
    id: `${scope}.adults.filterLabel`,
    defaultMessage: 'Adults'
  },
  children: {
    id: `${scope}.children.filterLabel`,
    defaultMessage: 'Children'
  },
  infants: {
    id: `${scope}.infants.filterLabel`,
    defaultMessage: 'Infants'
  },
  pets: {
    id: `${scope}.pets.filterLabel`,
    defaultMessage: 'Pets'
  },
  price: {
    id: `${scope}.price.filterLabel`,
    defaultMessage: 'Price'
  },
  bathrooms: {
    id: `${scope}.bathrooms.filterLabel`,
    defaultMessage: 'Bathrooms'
  },
  beds: {
    id: `${scope}.beds.filterLabel`,
    defaultMessage: 'Beds'
  },
  availability: {
    id: `${scope}.availability.filterLabel`,
    defaultMessage: 'Dates'
  },
  availabilityIn: {
    id: `${scope}.availabilityIn.filterLabel`,
    defaultMessage: 'Availability in'
  },
  los: {
    id: `${scope}.los.filterLabel`,
    defaultMessage: 'Full price'
  },
  location: {
    id: `${scope}.location.filterLabel`,
    defaultMessage: 'Location'
  },
  type: {
    id: `${scope}.type.filterLabel`,
    defaultMessage: 'Type'
  },
  suitability: {
    id: `${scope}.suitability.filterLabel`,
    defaultMessage: 'Suitability'
  },
  specialFeatures: {
    id: `${scope}.specialFeatures.filterLabel`,
    defaultMessage: 'Special'
  },
  familyFriendly: {
    id: `${scope}.familyFriendly.filterLabel`,
    defaultMessage: 'Family'
  },
  kitchenAppliances: {
    id: `${scope}.kitchenAppliances.filterLabel`,
    defaultMessage: 'Kitchen'
  },
  accessibilityFeatures: {
    id: `${scope}.accessibilityFeatures.filterLabel`,
    defaultMessage: 'Accessibility'
  },
  features: {
    id: `${scope}.features.filterLabel`,
    defaultMessage: 'Features'
  },
  keyword: {
    id: `${scope}.type.keywordLabel`,
    defaultMessage: 'Keyword'
  },
  sort: {
    id: `${scope}.sort.filterLabel`,
    defaultMessage: 'Sort by'
  },
  page: {
    id: `${scope}.page.filterLabel`,
    defaultMessage: 'Page'
  },
  flags: {
    id: `${scope}.flags.filterLabel`,
    defaultMessage: 'Flags'
  },
  safety: {
    id: `${scope}.safety.filterLabel`,
    defaultMessage: 'Safety'
  },
  relax: {
    id: `${scope}.relax.filterLabel`,
    defaultMessage: 'Relax'
  },
  locationFeatures: {
    id: `${scope}.location.filterLabel`,
    defaultMessage: 'Location'
  },
  tagsFlags: {
    id: `${scope}.tagsFlags.filterLabel`,
    defaultMessage: 'Tags'
  },
  discounts: {
    id: `${scope}.discounts.filterLabel`,
    defaultMessage: 'Discounts'
  }
});
