import { defineMessages } from 'react-intl';

export const scope = 'barcelona.templates.Search.ExtraFiltersModalButton';

export default defineMessages({
  moreFiltersButtonText: {
    id: `${scope}.moreFilters.button.text`,
    defaultMessage: 'More filters'
  },
  lessFiltersButtonText: {
    id: `${scope}.lessFilters.button.text`,
    defaultMessage: 'Less filters'
  }
});
