import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import HeaderNavbar from '@rentivo/gatsby-core/src/components/ui/HeaderNavbar';
import UpdateBar from '@rentivo/gatsby-core/src/components/ui/UpdateBar';
import TopBar from '@rentivo/gatsby-core/src/components/ui/TopBar';

const SearchLayoutComponent = ({children, menuItems, topBar, updateBar}) => {
  return (
    <Flex
      id="page-layout"
      className="layout search-layout"
      direction="column"
      h="100vh"
      overflow="hidden"
    >
      {(topBar && topBar.length > 0) && (
        <TopBar blocks={topBar}/>
      )}
      {(updateBar && updateBar.length > 0) && (
        <UpdateBar blocks={updateBar}/>
      )}
      <HeaderNavbar fluid {...menuItems}/>
      <Box
        className="layout-content"
        h="100%"
        position="relative"
        overflowY="auto"
      >
        {children}
      </Box>
    </Flex>
  )
};

export default SearchLayoutComponent;
