import { defineMessages } from 'react-intl';

export const scope = 'containers.SearchProvider.filters.PriceFilterPopover';

export default defineMessages({
  label: {
    id: `${scope}.label`,
    defaultMessage: 'Price'
  },
  setLabel: {
    id: `${scope}.set.label`,
    defaultMessage: 'Price'
  },
});
