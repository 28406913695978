import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/search/ResultsMeta/messages';

const ResultsMetaComponent = ({data}) => {
  const { took, total } = data || {};
  if(!took || !total) return null;
  return (
    <div className="results-list--meta"><FormattedMessage {...messages.statsTitle} values={{ took, total }}/></div>
  );
};

export default ResultsMetaComponent;
