import React, { useState, useRef, useEffect } from 'react';
import { window } from 'browser-monads';
import { Box, Collapse, useStyleConfig } from '@chakra-ui/react';
import WPGBlocks from '@rentivo/gatsby-core/src/components/wpg/Blocks';

const UpdateBar = ({blocks}) => {
  if(!blocks || !blocks.length) return null;

  const timeNow = useRef(new Date().getTime());

  const { outer, timing: { lastsFor = 24 } } = useStyleConfig('UpdateBar', {});

  const lastsForMill = (lastsFor * 1000 * 60 * 60);
  //const lastsForMill = 5000;

  const updateBarLocal = window.localStorage ? window.localStorage.getItem('updateBar') : undefined;
  const [isUpdateBarOpen, setIsUpdateBarOpen] = useState(
    (updateBarLocal === undefined || updateBarLocal === null || ((timeNow.current - parseInt(updateBarLocal)) >= lastsForMill))
  );

  const [isUpdateBarOpenDelayed, setIsUpdateBarOpenDelayed] = useState(false);

  const onCloseUpdateBar = () => {
    setIsUpdateBarOpenDelayed(false);
    window.localStorage.setItem('updateBar', `${new Date().getTime()}`);
  };

  useEffect(() => {
    if(isUpdateBarOpen && !isUpdateBarOpenDelayed) {
      setTimeout(() => {
        setIsUpdateBarOpenDelayed(true);
      }, 3000);
    }
  }, [isUpdateBarOpen]);

  return (
    <Collapse in={isUpdateBarOpenDelayed}>
      <Box
        position="relative"
        zIndex={1000}
        h="100%"
        css={{
          '.wpg-blocks': {
            height: "100%"
          }
        }}
        {...outer}
      >
        {isUpdateBarOpen && (<WPGBlocks blocks={blocks} childProps={{onCloseUpdateBar}} />)}
      </Box>
    </Collapse>
  );
};

export default UpdateBar;
