import React from 'react';
import { Helmet } from 'react-helmet';
import CdnImage from '@rentivo/gatsby-core/src/components/ui/CdnImage';
import smallCdn from '@rentivo/gatsby-core/src/components/schema/OpenGraph/small.cdn';
import largeCdn from '@rentivo/gatsby-core/src/components/schema/OpenGraph/large.cdn';

const OpenGraphComponent = ({title, defaultOgImage, defaultTwitterImage, description, imageSrc, imageSmall, url}) => {
  const cdn = (imageSmall) ? smallCdn : largeCdn;
  return (
    <>
      <Helmet>
        {title && <meta property="og:title" content={title}/>}
        {description && <meta name="og:description" content={description} />}
        <meta property="og:url" content={url}/>

        <meta name="twitter:card" content="summary"/>
        {title && <meta property="twitter:title" content={title}/>}
        {description && <meta name="twitter:description" content={description} />}
      </Helmet>
      {(imageSrc || defaultOgImage) && <CdnImage ogImage={true} uri={(imageSrc) ? imageSrc : defaultOgImage} {...cdn}/>}
      {(imageSrc || defaultTwitterImage) && <CdnImage twitterImage={true} uri={(imageSrc) ? imageSrc : defaultTwitterImage} {...cdn}/>}
    </>
  );
};

export default OpenGraphComponent;
