import { defineMessages } from 'react-intl';

export const scope = 'components.search.NoResults';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Oops, no results'
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Please try expanding your search criteria'
  }
});
