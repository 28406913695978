import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@chakra-ui/react';

const IconComponent = ({icon, type, ...rest}) => {
  if(!icon) return null;

  return (
    <Box as="span" type={type}>
      <FontAwesomeIcon icon={icon} {...rest}/>
    </Box>
  );
};

export default IconComponent;
