export const activePinClass = 'active-pin';

export const getMapMarker = (propertyId) => {
  const mapPin = document.getElementById(`search-marker-${propertyId}`);
  let mapPinParent = false;
  if(mapPin) {
    mapPinParent = mapPin.parentNode;
  }

  return { mapPin, mapPinParent };
};

export const handleMouseEnter = (propertyId) => {
  const mapMarker = getMapMarker(propertyId);
  if(mapMarker.mapPin && mapMarker.mapPinParent) {
    mapMarker.mapPin.classList.add(activePinClass);
    mapMarker.mapPinParent.classList.add(activePinClass);
  }
};

export const handleMouseLeave = (propertyId) => {
  const mapMarker = getMapMarker(propertyId);
  if(mapMarker.mapPin && mapMarker.mapPinParent) {
    mapMarker.mapPin.classList.remove(activePinClass);
    mapMarker.mapPinParent.classList.remove(activePinClass);
  }
};
