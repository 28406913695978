import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import ResultsMap from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/ResultsMap/component';
import {
  makeSearchDidInvalidate,
  makeSelectSearchError,
  makeSelectIsFetching,
  makeSelectSearchData,
  makeSelectSearchOnMapMove
} from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import {
  makeSelectSearchMapDefaultCenterConfig,
  makeSelectSearchMapDefaultZoomConfig,
  makeSelectSearchMapFuzzyRadiusConfig,
  makeSelectSearchMapMaxZoomConfig,
  makeSelectSearchMapMinZoomConfig,
  makeSelectSearchMapUseFuzzyRadiusConfig,
  selectMapKeyConfig,
  selectMapProviderConfig,
  selectMapStyleConfig,
  selectSearchMapExperiencesIncludeConfig,
  selectSearchMapExperiencesShowByDefaultConfig,
  selectSearchMapOnMoveDefaultConfig,
  selectSearchMapPlaceNamesUsePlaceNameConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import withFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/withFilter';
import { query } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/query';
import { tagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/tag';
import { setSearchOnMapMove } from '@rentivo/gatsby-core/src/containers/SearchProvider/actions';
import { withPageContext } from '@rentivo/gatsby-core/src/context/PageContext';
import { isCrawler } from '@rentivo/gatsby-core/src/utils/bots';

const mapStateToProps = createSelector(
  makeSelectSearchData(),
  makeSelectIsFetching(),
  makeSearchDidInvalidate(),
  makeSelectSearchError(),
  makeSelectSearchOnMapMove(),
  selectSearchMapOnMoveDefaultConfig,
  selectMapProviderConfig,
  selectMapKeyConfig,
  selectMapStyleConfig,
  makeSelectSearchMapDefaultZoomConfig(),
  makeSelectSearchMapMaxZoomConfig(),
  makeSelectSearchMapMinZoomConfig(),
  makeSelectSearchMapUseFuzzyRadiusConfig(),
  makeSelectSearchMapFuzzyRadiusConfig(),
  makeSelectSearchMapDefaultCenterConfig(),
  selectSearchMapPlaceNamesUsePlaceNameConfig,
  selectSearchMapExperiencesIncludeConfig,
  selectSearchMapExperiencesShowByDefaultConfig,
  (data, isFetching, didInvalidate, error, searchOnMapMove, searchOnMapMoveConfig, provider, mapKey, mapStyle, defaultZoom, maxZoom, minZoom, useFuzzyRadius, fuzzyRadius, defaultCenter, usePlaceNames, includeExperiences, showExperiencesByDefault) => ({
    data,
    isFetching,
    didInvalidate,
    error,
    searchOnMapMove,
    searchOnMapMoveConfig,
    provider,
    mapKey,
    mapStyle,
    defaultZoom,
    maxZoom,
    minZoom,
    useFuzzyRadius,
    fuzzyRadius,
    defaultCenter,
    usePlaceNames,
    includeExperiences,
    showExperiencesByDefault
  })
);

const mapDispatchToProps = dispatch => ({
  setSearchOnMapMove: (searchOnMapMove) => dispatch(setSearchOnMapMove(searchOnMapMove))
});

const enhance = compose(
  injectIntl,
  withPageContext(),
  withFilter('location', query, tagValue),
  withFilter('page'),
  connect(mapStateToProps, mapDispatchToProps)
);

const isCrawlerOrBot = isCrawler();
export default (isCrawlerOrBot) ? () => null : enhance(ResultsMap);
