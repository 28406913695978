import React from 'react';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import SearchProvider from '@rentivo/gatsby-core/src/containers/SearchProvider';
import SearchLayout from '@rentivo/gatsby-theme-barcelona/src/components/layouts/SearchLayout';
import ResultsMeta from '@rentivo/gatsby-core/src/components/search/ResultsMeta';
import {
  StyledSearchResultsMap,
  StyledPaginateResults,
} from '@rentivo/gatsby-theme-barcelona/src/templates/Search/styles';
import Loader from '@rentivo/gatsby-core/src/components/generic/Loader';
import SearchPageHeader from '@rentivo/gatsby-core/src/components/search/PageHeader';
import { LosFilter } from '@rentivo/gatsby-core';
import SearchCollectionTree from '@rentivo/gatsby-core/src/components/search/CollectionTree';
import SelectedFilters from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/SelectedFilters';
import SortResults from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/SortResults';
import ResultsList from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/ResultsList';
import SearchPageFooter from '@rentivo/gatsby-core/src/components/search/PageFooter';
import PrimaryFilters from '@rentivo/gatsby-theme-barcelona/src/templates/Search/components/PrimaryFilters';
import { useSearchLayout } from '@rentivo/gatsby-theme-barcelona/src/templates/Search/hooks';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-theme-barcelona/src/templates/Search/messages';
import Button from '@rentivo/gatsby-core/src/components/ui/ChakraButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faMapMarkedAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkedAlt';

const SearchComponent = ({menuItems, updateBar, topBar}) => {

  const { searchGridProps, mapGridProps, searchResultsGridProps, type, cardProps, isMapOpen, setIsMapOpen } = useSearchLayout();

  return (
    <SearchProvider>
      <Button
        type={isMapOpen ? 'white' : 'secondary'}
        size="lg"
        fontSize={{base: 'md', lg: 'lg'}}
        pr={{base: '16px', lg: '24px'}}
        pl={{base: '16px', lg: '24px'}}
        h={{base: '38px', lg: '48px'}}
        boxShadow="md"
        leftIcon={<FontAwesomeIcon icon={isMapOpen ? faTimes : faMapMarkedAlt}/>}
        onClick={() => setIsMapOpen(!isMapOpen)}
        position="fixed"
        right="24px"
        bottom="24px"
        zIndex={1000}
      >
        {isMapOpen ? <FormattedMessage {...messages.hideMap}/> : <FormattedMessage {...messages.showMap}/>}
      </Button>

      <SearchLayout menuItems={menuItems} updateBar={updateBar} topBar={topBar}>

        <Grid templateColumns="repeat(24, 1fr)" gap={0} height="100%" overflow="hidden" className="search-grid">

          <GridItem {...searchGridProps} className="search-area">

            <Flex
              className="search-content"
              height="100%"
              direction="column"
            >

              <PrimaryFilters/>

              <Box
                className="search-content"
                position="relative"
                h="100%"
              >
                <Box
                  className="search-content-inner"
                  layerStyle="filled"
                  pt={3}
                  pb={3}
                  pr={5}
                  pl={5}
                  overflow="hidden"
                  overflowY="scroll"
                  //style={{overflowY: (!isMobileOnly) ? 'scroll' : (canScrollOnMobile) ? 'auto' : 'hidden'}}
                >
                  <Box>
                    <SearchPageHeader/>
                    <LosFilter/>
                    <SearchCollectionTree/>
                    <SelectedFilters/>
                  </Box>

                  <Flex
                    justify="space-between"
                    align={{base: 'flex-start', sm: 'flex-end'}}
                    direction={{base: 'column', sm: 'row'}}
                  >
                    <ResultsMeta/>
                    <SortResults size="sm"/>
                  </Flex>

                  <ResultsList
                    components={{Meta: null}}
                    type={type}
                    grid={searchResultsGridProps}
                    card={cardProps}
                  />

                  <StyledPaginateResults/>
                  <SearchPageFooter/>
                </Box>
              </Box>

            </Flex>
          </GridItem>
          {isMapOpen && (
            <GridItem {...mapGridProps} className="map-area">
              <Loader/>
              <StyledSearchResultsMap/>
            </GridItem>
          )}
        </Grid>
      </SearchLayout>
    </SearchProvider>
  );
};

export default SearchComponent;
