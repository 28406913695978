import React from 'react';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import filterMessages from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/messages';
import { Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';
import { useLycanFormattedMessage } from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage';

const FilterTagComponent = ({filter, removeFilter, filterLabels, className}) => {
  const formatLycanMessage = useLycanFormattedMessage();
  const { id, urlParam, value, tagValue, showFilter = true } = filter;
  if(!showFilter) return null;
  const allFilterMessages = { ...filterMessages, ...filterLabels };
  const tagLabel = get(allFilterMessages, id, false);
  const formattedValue = (tagValue) ? tagValue : formatLycanMessage(value);
  return (
    <Tag closable size="md" className={className}>
      <TagLabel><strong>{tagLabel ? <FormattedMessage {...tagLabel} /> : 'No Label'}</strong>: {formattedValue}</TagLabel>
      <TagCloseButton onClick={() => removeFilter(urlParam)} />
    </Tag>
  );
};

export default FilterTagComponent;
