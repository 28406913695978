import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import SortResults from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/SortResults/component';
import withFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/withFilter';
import { query } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/SortResults/query';
import { tagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/SortResults/tag';

const enhance = compose(
  withFilter('sort', query, tagValue)
);

SortResults.defaultProps = {
  className: 'results-sort',
  size: 'md'
};

SortResults.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ]),
  size: PropTypes.string
};

export default enhance(SortResults);
