import { defineMessages } from 'react-intl';

export const scope = 'components.search.PageHeader';

export const messagesObject = {
  defaultPageTitle: {
    id: `${scope}.default.page.title`,
    defaultMessage: 'Holiday rentals'
  },
  defaultPageSubtitle: {
    id: `${scope}.default.page.subtitle`,
    defaultMessage: 'Find your perfect holiday accommodation with our hand-curated selection of holiday rentals for couples, families & large groups.'
  },
  defaultSeoTitle: {
    id: `${scope}.default.seo.title`,
    defaultMessage: 'Holiday rentals'
  },
  defaultSeoDesc: {
    id: `${scope}.default.seo.desc`,
    defaultMessage: 'Find your perfect holiday accommodation with our hand-curated selection of holiday rentals for couples, families & large groups.'
  },
  placePageTitle: {
    id: `${scope}.place.page.title`,
    defaultMessage: 'Holiday rentals in {placeName}'
  },
  placePageSubtitle: {
    id: `${scope}.place.page.subtitle`,
    defaultMessage: 'Find your perfect holiday accommodation with our hand-curated selection of holiday rentals in {placeName}.'
  },
  placeSeoTitle: {
    id: `${scope}.place.seo.title`,
    defaultMessage: 'Holiday rentals in {placeName} {sep} {siteTitle}'
  },
  placeSeoDesc: {
    id: `${scope}.place.seo.desc`,
    defaultMessage: 'Find your perfect holiday accommodation with our hand-curated selection of holiday rentals in {placeName}.'
  },
  collectionPageTitle: {
    id: `${scope}.collection.page.title`,
    defaultMessage: 'Holiday rentals in {collectionName}'
  },
  collectionPageSubtitle: {
    id: `${scope}.collection.page.subtitle`,
    defaultMessage: 'Find your perfect holiday accommodation with our hand-curated selection of holiday rentals in {collectionName}.'
  },
  collectionSeoTitle: {
    id: `${scope}.collection.seo.title`,
    defaultMessage: 'Holiday rentals in {collectionName} {sep} {siteTitle}'
  },
  collectionSeoDesc: {
    id: `${scope}.collection.seo.desc`,
    defaultMessage: 'Find your perfect holiday accommodation with our hand-curated selection of holiday rentals in {placeName}.'
  }
};

export default defineMessages({
  defaultPageTitle: {
    id: `${scope}.default.page.title`,
    defaultMessage: 'Holiday rentals'
  },
  defaultPageSubtitle: {
    id: `${scope}.default.page.subtitle`,
    defaultMessage: 'Find your perfect holiday accommodation with our hand-curated selection of holiday rentals for couples, families & large groups.'
  },
  defaultSeoTitle: {
    id: `${scope}.default.seo.title`,
    defaultMessage: 'Holiday rentals'
  },
  defaultSeoDesc: {
    id: `${scope}.default.seo.desc`,
    defaultMessage: 'Find your perfect holiday accommodation with our hand-curated selection of holiday rentals for couples, families & large groups.'
  },
  placePageTitle: {
    id: `${scope}.place.page.title`,
    defaultMessage: 'Holiday rentals in {placeName}'
  },
  placePageSubtitle: {
    id: `${scope}.place.page.subtitle`,
    defaultMessage: 'Find your perfect holiday accommodation with our hand-curated selection of holiday rentals in {placeName}.'
  },
  placeSeoTitle: {
    id: `${scope}.place.seo.title`,
    defaultMessage: 'Holiday rentals in {placeName} {sep} {siteTitle}'
  },
  placeSeoDesc: {
    id: `${scope}.place.seo.desc`,
    defaultMessage: 'Find your perfect holiday accommodation with our hand-curated selection of holiday rentals in {placeName}.'
  },
  collectionPageTitle: {
    id: `${scope}.collection.page.title`,
    defaultMessage: 'Holiday rentals in {collectionName}'
  },
  collectionPageSubtitle: {
    id: `${scope}.collection.page.subtitle`,
    defaultMessage: 'Find your perfect holiday accommodation with our hand-curated selection of holiday rentals in {collectionName}.'
  },
  collectionSeoTitle: {
    id: `${scope}.collection.seo.title`,
    defaultMessage: 'Holiday rentals in {collectionName} {sep} {siteTitle}'
  },
  collectionSeoDesc: {
    id: `${scope}.collection.seo.desc`,
    defaultMessage: 'Find your perfect holiday accommodation with our hand-curated selection of holiday rentals in {placeName}.'
  }
});
