import { defineMessages } from 'react-intl';

export const scope = 'barcelona.templates.Search';

export default defineMessages({
  showMap: {
    id: `${scope}.showMap.label`,
    defaultMessage: 'Show map'
  },
  hideMap: {
    id: `${scope}.hideMap.label`,
    defaultMessage: 'Hide map'
  },
});
